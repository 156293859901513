"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslations } from "next-intl"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { useTenant } from "@/app/[fqdn]/[locale]/TenantProvider"
import { api } from "@/app/trpc"
import { Card, CardContent, CardFooter } from "@/components/ui/Card"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormSubmitButton,
} from "@/components/ui/Form"
import { Input } from "@/components/ui/Input"
import { useToastFactory } from "@/components/ui/Sonner"
import { LINKS } from "@/constants/links"
import { useTranslatedSchema } from "@/i18n/hooks/useTranslatedSchema"
import { useRouter } from "@/i18n/routing"
import { tFnHook } from "@/i18n/types"

const formSchema = (t: tFnHook) =>
  z.object({
    email: z.string().min(1, t("email.min")).email(t("email.invalid")),
  })

type FormValues = z.infer<ReturnType<typeof formSchema>>

export function LoginForm(props: { nextUrl?: string }) {
  const t = useTranslations("login_page.login.form")
  const schema = useTranslatedSchema("login_page.login.schema", formSchema)
  const toast = useToastFactory()
  const router = useRouter()
  const tenant = useTenant()

  const loginMutation = api.users.login.useMutation({
    onError: (error) => {
      if (error.data?.code === "NOT_FOUND") {
        toast.onError({
          title: t("on_error_not_found.title"),
          description: t("on_error_not_found.description"),
        })
        return
      }

      toast.onError()
    },
    onSuccess: () => {
      toast.onSuccess({
        title: t("on_success.title"),
        description: t("on_success.description"),
      })

      router.push(LINKS.authVerifyRequest)
    },
  })

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    defaultValues: { email: "" },
  })

  function onSubmit({ email }: FormValues) {
    loginMutation.mutate({ email, tenantId: tenant.tenantId, nextUrl: props.nextUrl ?? LINKS.home })
  }

  return (
    <Card className="w-full max-w-[570px] border-2">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="px-12 pt-9">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("email.label")}</FormLabel>

                  <FormControl>
                    <Input
                      type="email"
                      className="border-2 placeholder:text-nano-gray-800"
                      placeholder="Zde zadejte váš email"
                      {...field}
                      value={field.value}
                    />
                  </FormControl>

                  <FormDescription className="text-nano-gray-700">{t("email.description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>

          <CardFooter className="px-12 pb-9">
            <FormSubmitButton className="w-full rounded-md max-sm:h-auto" isLoading={loginMutation.isPending}>
              {t("submit_button")}
            </FormSubmitButton>
          </CardFooter>
        </form>
      </Form>
    </Card>
  )
}
